import { combineReducers } from 'redux';
import storage from 'redux-persist/lib/storage';
import { SET_EMAIL_NEW_PASSWORD } from '../container/Auth/ResetPassword/constants';
import { MASTER_DATA } from './masterData/constants';

export const rootPersistConfig = {
  key: 'root',
  storage,
  keyPrefix: 'redux-',
  whitelist: [],
};

const initialState = {
  userOrder: {
    addressBook: [],
    addressBookMeta: {}
  },
  resetPassword: {
    email: null,
  },
  masterData: {},
}

function exampleReducer(state = initialState, action) {
  if (action.type === SET_EMAIL_NEW_PASSWORD) {
    state.resetPassword.email =  action.payload.data;
  }

  if (action.type === MASTER_DATA) {
    state.masterData =  action.payload.data;
  }

  return state
}

const rootReducer = combineReducers({
  root: exampleReducer
});

export default rootReducer;
