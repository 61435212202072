import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { useEffect, useState } from 'react';
import { useMutation } from '@tanstack/react-query';
import { Navigate, useLocation } from 'react-router-dom';

import { dispatch, store } from '../redux/store';
import { useAuthContext } from './useAuthContext';
import LoadingScreen from '../components/loading-screen';
import { getMasterData, setBearerToken } from '../redux/masterData/httpClients';
import { getMasterData as getMasterDataAction } from '../redux/masterData/actions';
import { PATH_AUTH } from '../routes/paths';
import { ROLE } from '../utils/constants';
import PermissionDenied from '../components/PermissionDenied';

AuthGuard.propTypes = {
  path: PropTypes.string,
  children: PropTypes.node,
};

export default function AuthGuard({ children, path }) {
  const { isAuthenticated, isInitialized, user } = useAuthContext();

  const { root: { masterData } } = store.getState();

  const { pathname } = useLocation();

  const [requestedLocation, setRequestedLocation] = useState(null);

  const { mutate } = useMutation({
    mutationFn: token => {
      setBearerToken(token);
      return getMasterData();
    },
    onSuccess: ({ data: { data, status, errors } }) => {
      if (status) {
        dispatch(getMasterDataAction(data));
      } else {
        errors.map(({ message }) => toast.error(message))
      }
    },
  })

  const accessToken = localStorage.getItem('accessToken');

  useEffect(() => {
    if (user?.role_id && accessToken && isEmpty(masterData)) {
      mutate(accessToken);
    }
  }, [user])

  if (user?.role_id && user?.role_id !== ROLE[path].id ) {
    return <PermissionDenied />;
  }

  if (!isAuthenticated && !accessToken) {
    if (pathname !== requestedLocation) {
      setRequestedLocation(pathname);
    }
    return <Navigate to={PATH_AUTH.login} />;
  }

  if (requestedLocation && pathname !== requestedLocation) {
    setRequestedLocation(null);
    return <Navigate to={requestedLocation} />;
  }

  // if (!isInitialized || isEmpty(masterData)) {
  if (!isInitialized) {
    return <LoadingScreen />;
  }

  return <> {children} </>;
}
