// ----------------------------------------------------------------------
import { ROLE } from '../utils/constants';

function path(root, sublink) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = '/auth';
const ROOTS_ADMIN = '/admin';
const ROOTS_USER = '/user';
const ROOTS_SELLER = '/seller';

export const PATH_AFTER_LOGIN = {
  [ROLE.user.id] : '/user/dashboard',
  [ROLE.admin.id] : '/admin/dashboard',
  [ROLE.seller.id] : '/seller/dashboard',
}

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, '/login'),
  register: path(ROOTS_AUTH, '/register'),
  loginUnprotected: path(ROOTS_AUTH, '/login-unprotected'),
  registerUnprotected: path(ROOTS_AUTH, '/register-unprotected'),
  verify: path(ROOTS_AUTH, '/verify'),
  resetPassword: path(ROOTS_AUTH, '/reset-password'),
  newPassword: path(ROOTS_AUTH, '/new-password'),
};

export const PATH_PAGE = {
  comingSoon: '/coming-soon',
  maintenance: '/maintenance',
  pricing: '/pricing',
  payment: '/payment',
  about: '/about-us',
  contact: '/contact-us',
  faqs: '/faqs',
  page403: '/403',
  page404: '/404',
  page500: '/500',
  components: '/components',
};

export const LINK_TELEGRAM = 'https://t.me/shopaccountslive';
export const LINK_TELEGRAM_SP = 'https://t.me/shopaccountslive';

export const PATH_ADMIN_DASHBOARD = {
  root: ROOTS_ADMIN,
  general: {
    dashboard: path(ROOTS_ADMIN, '/dashboard'),
    settingsSystem: path(ROOTS_ADMIN, '/system-settings'),
  },
  order: {
    root: path(ROOTS_ADMIN, '/orders'),
    list: path(ROOTS_ADMIN, '/orders/list'),
    view: (id) => path(ROOTS_ADMIN, `/orders/${id}/view`),
    edit: (id) => path(ROOTS_ADMIN, `/orders/${id}/edit`),
  },
  userActive: {
    root: path(ROOTS_ADMIN, '/users-activities'),
    list: path(ROOTS_ADMIN, '/users-activities/list'),
  },
  user: {
    root: path(ROOTS_ADMIN, '/users'),
    list: path(ROOTS_ADMIN, '/users/list'),
    view: (id) => path(ROOTS_ADMIN, `/users/${id}/view`),
  },
  ticket: {
    root: path(ROOTS_ADMIN, '/tickets'),
    list: path(ROOTS_ADMIN, '/tickets/list'),
    view: (id) => path(ROOTS_ADMIN, `/tickets/${id}/view`),
  },
  transaction: {
    root: path(ROOTS_ADMIN, '/transactions'),
    all: path(ROOTS_ADMIN, '/transactions/all'),
    deposit: path(ROOTS_ADMIN, '/transactions/deposits'),
  },
  provider: {
    root: path(ROOTS_ADMIN, '/provider'),
    edit: (id) => path(ROOTS_ADMIN, `/provider/${id}`),
    create: path(ROOTS_ADMIN, '/provider/create'),
  },
  product: {
    root: path(ROOTS_ADMIN, '/product'),
    list: path(ROOTS_ADMIN, '/product/list'),
    edit: (id) => path(ROOTS_ADMIN, `/product/${id}`),
    view: (id) => path(ROOTS_ADMIN, `/product/${id}/view`),
    child_create: (id) => path(ROOTS_ADMIN, `/product/${id}/create`),
    child_edit: (id, idProvider) => path(ROOTS_ADMIN, `/product/${id}/edit/${idProvider}`),
    child_import: (id) => path(ROOTS_ADMIN, `/product/${id}/import`),
    create: path(ROOTS_ADMIN, '/product/create'),
    import: path(ROOTS_ADMIN, '/product/import'),
    available: (idProduct) => path(ROOTS_ADMIN, `/product/account-available/${idProduct}/list`),
  },
};

export const PATH_USER_DASHBOARD = {
  root: ROOTS_USER,
  general: {
    dashboard: path(ROOTS_USER, '/dashboard'),
  },
  ticket: {
    root: path(ROOTS_USER, '/tickets'),
    list: path(ROOTS_USER, '/tickets/list'),
    view: (id) => path(ROOTS_USER, `/tickets/${id}/view`),
  },
  product: {
    root: path(ROOTS_USER, '/products'),
    list: path(ROOTS_USER, '/products/list'),
    view: (id) => path(ROOTS_USER, `/products/${id}/view`),
  },
  transaction: {
    root: path(ROOTS_USER, '/transactions'),
    all: path(ROOTS_USER, '/transactions/all'),
    deposit: path(ROOTS_USER, '/transactions/deposits'),
  },
  order: {
    root: path(ROOTS_USER, '/orders'),
    list: path(ROOTS_USER, '/orders/list'),
    edit: (id) => path(ROOTS_USER, `/orders/${id}/edit`),
    view: (id) => path(ROOTS_USER, `/orders/${id}/view`),
  },
  user: {
    root: path(ROOTS_USER, '/account'),
    profile: path(ROOTS_USER, '/account/profile'),
    referral: path(ROOTS_USER, '/account/referral'),
  },
};

export const PATH_SELLER_DASHBOARD = {
  root: ROOTS_SELLER,
  general: {
    dashboard: path(ROOTS_SELLER, '/dashboard'),
  },
  ticket: {
    root: path(ROOTS_SELLER, '/tickets'),
    list: path(ROOTS_SELLER, '/tickets/list'),
    view: (id) => path(ROOTS_SELLER, `/tickets/${id}/view`),
  },
  transaction: {
    root: path(ROOTS_SELLER, '/transactions'),
    all: path(ROOTS_SELLER, '/transactions/all'),
    deposit: path(ROOTS_SELLER, '/transactions/deposits'),
  },
  pricing: {
    root: path(ROOTS_SELLER, '/pricing'),
    list: path(ROOTS_SELLER, '/pricing/list'),
  },
  order: {
    root: path(ROOTS_SELLER, '/orders'),
    list: path(ROOTS_SELLER, '/orders/list'),
    create: path(ROOTS_SELLER, '/orders/create'),
    view: (id) => path(ROOTS_SELLER, `/orders/${id}/view`),
  },
  referral: {
    root: path(ROOTS_SELLER, '/referral'),
    analytics: path(ROOTS_SELLER, '/referral/analytics'),
    orders: path(ROOTS_SELLER, '/referral/orders'),
    users: path(ROOTS_SELLER, '/referral/users'),
    usersView: (id) => path(ROOTS_SELLER, `/referral/users/${id}/view`),
    deposits: path(ROOTS_SELLER, '/referral/deposits'),
  },
  user: {
    root: path(ROOTS_SELLER, '/account'),
    profile: path(ROOTS_SELLER, '/account/profile'),
    referral: path(ROOTS_SELLER, '/account/referral'),
  },
};
