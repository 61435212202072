import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import EditLocationIcon from "@mui/icons-material/EditLocation";
import PriceChangeIcon from "@mui/icons-material/PriceChange";
import SettingsIcon from '@mui/icons-material/Settings';
import EditNoteIcon from "@mui/icons-material/EditNote";
import TelegramIcon from '@mui/icons-material/Telegram';

import { PATH_ADMIN_DASHBOARD, LINK_TELEGRAM } from '../../../routes/paths';
import SvgColor from "../../../components/svg-color";
import Iconify from '../../../components/iconify';

const icon = (name) => (
  <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />
);

const ICONS = {
  dashboard: icon('ic_dashboard'),
  user: icon('ic_user'),
  order: icon('ic_ecommerce'),
  ticket: <SupportAgentIcon />,
  addressBook: <EditLocationIcon />,
  pricing: <PriceChangeIcon />,
  deposit: icon('ic_invoice'),
  telegram: <TelegramIcon />,
  userActivities: <EditNoteIcon />,
  settingsSystem: <SettingsIcon />,
  product: <Iconify icon="fluent-mdl2:product-variant" />,
  provider: <Iconify icon="fluent-mdl2:accounts" />,
};

const adminNavigation = [
  {
    subheader: 'general',
    items: [
      { title: 'dashboard', path: PATH_ADMIN_DASHBOARD.general.dashboard, icon: ICONS.dashboard },
      { title: 'system setting', path: PATH_ADMIN_DASHBOARD.general.settingsSystem, icon: ICONS.settingsSystem },
    ],
  },
  {
    subheader: 'management',
    items: [
      {
        title: 'transaction',
        path: PATH_ADMIN_DASHBOARD.transaction.root,
        icon: ICONS.deposit,
        children: [
          { title: 'all', path: PATH_ADMIN_DASHBOARD.transaction.all },
          { title: 'deposit', path: PATH_ADMIN_DASHBOARD.transaction.deposit },
        ],
      },
      {
        title: 'orders',
        path: PATH_ADMIN_DASHBOARD.order.root,
        icon: ICONS.order,
        children: [
          { title: 'list', path: PATH_ADMIN_DASHBOARD.order.list },
        ],
      },
      {
        title: 'product',
        path: PATH_ADMIN_DASHBOARD.product.root,
        icon: ICONS.product,
        children: [
          { title: 'list', path: PATH_ADMIN_DASHBOARD.product.list },
        ],
      },
      {
        title: 'users',
        path: PATH_ADMIN_DASHBOARD.user.root,
        icon: ICONS.user,
        children: [
          { title: 'list', path: PATH_ADMIN_DASHBOARD.user.list },
        ],
      },
      {
        title: 'tickets',
        path: PATH_ADMIN_DASHBOARD.ticket.root,
        icon: ICONS.ticket,
        children: [
          { title: 'list', path: PATH_ADMIN_DASHBOARD.ticket.list },
        ],
      },
    ],
  },
  {
    subheader: 'contact',
    items: [
      { title: 'telegram', path: LINK_TELEGRAM, icon: ICONS.telegram },
    ],
  },
]

export default adminNavigation
