import { Suspense, lazy as lazyLoad } from 'react';

import LoadingScreen from '../components/loading-screen';

const lazy = (componentImport) => lazyLoad(() => new Promise((resolve, reject) => {

  const hasRefreshed = JSON.parse(
    window.sessionStorage.getItem('retry-lazy-refreshed') || 'false'
  );

  componentImport().then((component) => {
    window.sessionStorage.setItem('retry-lazy-refreshed', 'false');
    resolve(component);
  }).catch((error) => {
    if (!hasRefreshed && error.name === 'ChunkLoadError') {
      window.sessionStorage.setItem('retry-lazy-refreshed', 'true');
      return window.location.reload();
    }
    reject(error);
  });
}));

const Loadable = (Component) => (props) =>
  (
    <Suspense fallback={<LoadingScreen />}>
      <Component {...props} />
    </Suspense>
  );

export const LoginPage = Loadable(lazy(() => import('../container/Auth/Login')));
export const RegisterPage = Loadable(lazy(() => import('../container/Auth/Register')));
export const ResetPasswordPage = Loadable(lazy(() => import('../container/Auth/ResetPassword')));
export const NewPasswordPage = Loadable(lazy(() => import('../container/Auth/NewPassword')));

export const Error404 = Loadable(lazy(() => import('../container/ErrorPage/404')));
export const Error500 = Loadable(lazy(() => import('../container/ErrorPage/500')));

export const UserDashboard = Loadable(lazy(() => import('../container/Users/Dashboard')));
export const UserTicketList = Loadable(lazy(() => import('../container/Users/Ticket/List')));
export const UserTicketView = Loadable(lazy(() => import('../container/Users/Ticket/View')));
export const UserDeposit = Loadable(lazy(() => import('../container/Users/Deposit')));
export const UserOrderList = Loadable(lazy(() => import('../container/Users/Order/List')));
export const UserOrderDetail = Loadable(lazy(() => import('../container/Users/Order/View')));
export const UserAccount = Loadable(lazy(() => import('../container/Users/MyAccount/Profile')));
export const UserReferral = Loadable(lazy(() => import('../container/Users/MyAccount/Referral')));
export const UserTransactionAll = Loadable(lazy(() => import('../container/Users/TransactionAll')));
export const UserProductList = Loadable(lazy(() => import('../container/Users/Product/List')));
export const UserProductView = Loadable(lazy(() => import('../container/Users/Product/View')));

export const AdminDashboard = Loadable(lazy(() => import('../container/Admin/Dashboard')));
export const AdminSystemSettings = Loadable(lazy(() => import('../container/Admin/SystemSettings')));
export const AdminProvider = Loadable(lazy(() => import('../container/Admin/Provider/List')));
export const AdminProviderCreate = Loadable(lazy(() => import('../container/Admin/Provider/Create')));
export const AdminProviderEdit = Loadable(lazy(() => import('../container/Admin/Provider/Edit')));
export const AdminProduct = Loadable(lazy(() => import('../container/Admin/Product/List')));
export const AdminProductChildCreate = Loadable(lazy(() => import('../container/Admin/Product/child/Create')));
export const AdminProductCreate = Loadable(lazy(() => import('../container/Admin/Product/Create')));
export const AdminProductEdit = Loadable(lazy(() => import('../container/Admin/Product/Edit')));
export const AdminProductView = Loadable(lazy(() => import('../container/Admin/Product/View')));
export const AdminProductChildEdit = Loadable(lazy(() => import('../container/Admin/Product/child/Edit')));
export const AdminProductChildImport = Loadable(lazy(() => import('../container/Admin/Product/child/Import')));
export const AdminProductListAvailable = Loadable(lazy(() => import('../container/Admin/Product/ListAvailable')));
export const AdminOrderList = Loadable(lazy(() => import('../container/Admin/Order/List')));
export const AdminOrderView = Loadable(lazy(() => import('../container/Admin/Order/View')));
export const AdminUserList = Loadable(lazy(() => import('../container/Admin/User/List')));
export const AdminUserView = Loadable(lazy(() => import('../container/Admin/User/View')));
export const AdminTicketList = Loadable(lazy(() => import('../container/Admin/Ticket/List')));
export const AdminTicketView = Loadable(lazy(() => import('../container/Admin/Ticket/View')));
export const AdminDepositList = Loadable(lazy(() => import('../container/Admin/Deposit/List')));
export const AdminTransactionAll = Loadable(lazy(() => import('../container/Admin/TransactionAll')));
