import PropTypes from 'prop-types';
import { forwardRef } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Box, Link } from '@mui/material';

const Logo = forwardRef(({ disabledLink = false, sx, ...other }, ref) => {

  // const logoUrl = themeLayout === 'mini' ? `/assets/images/logo/${mode}/logo-mini.png` : `/assets/images/logo/${mode}/logo.png`
  const logoUrl = `/assets/images/logo/sa.png`

  const logo = (
    <Box
      component="img"
      src={logoUrl}
      sx={{ width: '135px', height: '35px', objectFit: 'cover', cursor: 'pointer', ...sx }}
    />
  );


  const renderLogo = (
    <Box
      ref={ref}
      component="div"
      sx={{
        width: 40,
        height: 40,
        display: 'inline-flex',
        ...sx,
      }}
      {...other}
    >
      {logo}
    </Box>
  );

  if (disabledLink) {
    return renderLogo;
  }

  return (
    <Link component={RouterLink} to="/" sx={{ display: 'contents' }}>
      {logo}
    </Link>
  );
});

Logo.propTypes = {
  sx: PropTypes.object,
  disabledLink: PropTypes.bool,
};

export default Logo;
