import { Navigate, useRoutes } from 'react-router-dom';

import {
  AdminDashboard,
  AdminOrderView,
  AdminOrderList,
  AdminUserList,
  AdminUserView,
  AdminTicketList,
  AdminTicketView,
  AdminDepositList,
  AdminTransactionAll,
  AdminSystemSettings,
  AdminProvider,
  AdminProviderCreate,
  AdminProviderEdit,
  AdminProduct,
  AdminProductCreate,
  AdminProductEdit,
  AdminProductView,
  AdminProductListAvailable,
  AdminProductChildEdit,
  AdminProductChildCreate,
  AdminProductChildImport,

  UserTicketList,
  UserTicketView,
  UserDeposit,
  UserOrderList,
  UserAccount,
  UserOrderDetail,
  UserDashboard,
  UserTransactionAll,
  UserReferral,
  UserProductList,
  UserProductView,

  LoginPage,
  RegisterPage,
  NewPasswordPage,
  ResetPasswordPage,

  Error404,
  Error500,
} from './elements';
import GuestGuard from "../auth/GuestGuard";
import AuthGuard from "../auth/AuthGuard";
import MainLayout from "../layouts/main";
import DashboardLayout from '../layouts/dashboard';
import RoleBasedGuard from '../auth/RoleBasedGuard';
import { ROLE } from '../utils/constants';
import CompactLayout from "../layouts/compact";

export default function Router() {
  return useRoutes([
    {
      path: 'auth',
      children: [
        { element: <Navigate to="auth/login" replace />, index: true },
        {
          path: 'login',
          element: (
            <GuestGuard>
              <LoginPage />
            </GuestGuard>
          ),
        },
        {
          path: 'reset-password',
          element: (
            <GuestGuard>
              <ResetPasswordPage />
            </GuestGuard>
          ),
        },
        {
          path: 'new-password',
          element: (
            <GuestGuard>
              <NewPasswordPage />
            </GuestGuard>
          ),
        },
        {
          path: 'register',
          element: (
            <GuestGuard>
              <RegisterPage />
            </GuestGuard>
          ),
        },
      ],
    },
    // Main Routes
    {
      element: <MainLayout />,
      children: [
        { element: <Navigate to="auth/login" replace />, index: true },
      ],
    },
    {
      path: 'user',
      element: (
        <AuthGuard path="user">
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to="/dashboard" replace />, index: true },
        { path: 'dashboard', element: <UserDashboard /> },
        {
          path: 'tickets',
          children: [
            { element: <Navigate to="/user/tickets/list" replace />, index: true },
            { path: 'list', element: <UserTicketList /> },
            { path: ':id/view', element: <UserTicketView /> },
          ],
        },
        {
          path: 'account',
          children: [
            { element: <Navigate to="/user/account/profile" replace />, index: true },
            { path: 'profile', element: <UserAccount /> },
            { path: 'referral', element: <UserReferral /> },
          ],
        },
        {
          path: 'products',
          children: [
            { element: <Navigate to="/user/products/list" replace />, index: true },
            { path: 'list', element: <UserProductList /> },
            { path: ':id/view', element: <UserProductView /> },
          ],
        },
        {
          path: 'transactions',
          children: [
            { element: <Navigate to="/user/transactions/deposits" replace />, index: true },
            { path: 'all', element: <UserTransactionAll /> },
            { path: 'deposits', element: <UserDeposit /> },
          ],
        },
        {
          path: 'orders',
          children: [
            { element: <Navigate to="/user/orders/list" replace />, index: true },
            { path: 'list', element: <UserOrderList /> },
            { path: ':id/view', element: <UserOrderDetail /> },
          ],
        },
      ]
    },
    {
      path: 'admin',
      element: (
        <RoleBasedGuard roles={[ROLE.admin.name]} hasContent>
          <DashboardLayout />
        </RoleBasedGuard>
      ),
      children: [
        { element: <Navigate to="/" replace />, index: true },
        { path: 'dashboard', element: <AdminDashboard /> },
        {
          path: 'system-settings',
          children: [
            { element: <Navigate to="/admin/system-settings/list" replace />, index: true },
            { path: 'list', element: <AdminSystemSettings /> },
          ],
        },
        {
          path: 'provider',
          children: [
            { element: <AdminProvider />, index: true },
            { path: 'create', element: <AdminProviderCreate /> },
            { path: ':id', element: <AdminProviderEdit /> },
          ],
        },
        {
          path: 'product',
          children: [
            { path: 'list', element: <AdminProduct /> },
            { path: 'create', element: <AdminProductCreate /> },
            { path: ':id', element: <AdminProductEdit /> },
            { path: ':id/view', element: <AdminProductView /> },
            { path: ':id/edit/:provider_id', element: <AdminProductChildEdit /> },
            { path: ':id/import', element: <AdminProductChildImport /> },
            { path: ':id/create', element: <AdminProductChildCreate /> },
            { path: 'account-available/:productId/list', element: <AdminProductListAvailable /> },
          ],
        },
        {
          path: 'orders',
          children: [
            { element: <Navigate to="/admin/orders/list" replace />, index: true },
            { path: 'list', element: <AdminOrderList /> },
            { path: ':id/view', element: <AdminOrderView /> },
          ],
        },
        {
          path: 'users',
          children: [
            { element: <Navigate to="/admin/users/list" replace />, index: true },
            { path: 'list', element: <AdminUserList /> },
            { path: ':id/view', element: <AdminUserView /> },
          ],
        },
        {
          path: 'transactions',
          children: [
            { element: <Navigate to="/admin/transactions/deposits" replace />, index: true },
            { path: 'all', element: <AdminTransactionAll /> },
            { path: 'deposits', element: <AdminDepositList /> },
          ],
        },
        {
          path: 'tickets',
          children: [
            { element: <Navigate to="/admin/tickets/list" replace />, index: true },
            { path: 'list', element: <AdminTicketList /> },
            { path: ':id/view', element: <AdminTicketView /> },
          ],
        },
      ]
    },
    {
      element: <CompactLayout />,
      children: [
        { path: '404', element: <Error404 /> },
        { path: '500', element: <Error500 /> },
      ],
    },
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}
